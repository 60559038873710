@font-face {
  font-family: prosto;
  src: url(./ProstoOne-Regular.ttf);
}


html {
  font-family: prosto;
}

body {
  height: 100vh;
  overflow: auto;
  
  background-image: linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,1)), url('../img/wellness.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  
  margin: 0;
  font-size: 16px;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}


footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5vh;
  
  background-color: rgba(255, 255, 255, 0.9);
  border: 3px outset rgba(200, 200, 200, 0.3);
}


header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 10vh;
  
  background-color: rgba(255, 255, 255, 0.9);
  border: 3px inset rgba(200, 200, 200, 0.6);
  margin: 0;
  padding: 0;
}


iframe {
  height: 100%;
  width: 100%;
}


li {
  /* list-style-type: none; */
  margin-bottom: 1rem;
}


.menu-button {
  position: fixed;
  font-size: 2rem;
  padding: 0.1rem 0.8rem;
  z-index: 3;
}

nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  max-width: 23vw;
  padding: 3rem 0 0 0;
  margin: 0;
  min-width: 50vw;
  border-radius: 0 4rem 4rem 1rem;
  
  background-color: rgba(255, 245, 231, 0.9);
  z-index: 2;
}

nav button {
  background-color: rgba(255,237,211,.6);
  border: thick outset rgba(255,237,211,.4);
  margin: 0.2rem;
  width: auto;
  font-size: 1.3rem;
}

nav li {
  list-style-type: none;
}

nav li a {
  display: block;
  color: blue;
  text-decoration: none;
  text-align: center;
  
  border: thick solid lightgreen;
  border-top: none;
  border-left: none;
  border-radius: 50%;
  background-color: rgba(144, 253, 216, 0.2);
  font-size: 1.2rem;
  font-weight: 900;
  padding: 1rem 0.5rem 0.5rem 1rem;
  width: 25vw;
}

nav ul {
  width: 100%;
  padding: 0;
  margin: 0;
}

nav ul > ul {
  margin-left: 1rem;
}

nav ul > li {
  padding-left: 1rem;
}


main {
  display: inline-block;
  position: absolute;
  top: 10vh;
  left: 5vw;
  right: 0;
  bottom: 5vh;
  padding: 1.5rem 1rem 3rem 0;
  
  overflow-y: scroll;
}

main div {
  padding: 0 0 3rem 0;
}


summary {
  background-color: rgb(255, 237, 211, 0.6);
  margin: 0.2rem;
  border: thick outset rgb(255, 237, 211, 0.4);
}

details {
  border: thin double rgb(253, 241, 222);
}

details > details,
details > a {
  margin-left: 2vw;
}

details summary {
  cursor: pointer;
}

details img {
  max-width: 80%;
}


th {
  text-align: left;
  border-bottom: 3px outset rgba(150, 150, 150, 0.3);
  border-right: 3px outset rgba(150, 150, 150, 0.3);
}

tr {
  break-inside: avoid;
  border-bottom: thick outset rgba(150, 150, 150, 0.3);
}